<template>
  <AppMainWindowContainer
    type="small"
    logo-type="big"
  >
    <template #header>
      <div class="password-reset__header">
        <h1 class="header-text">
          {{ $t('reset_password.header') }}
        </h1>
      </div>
    </template>

    <template #center>
      <form
        class="password-reset__form"
        @submit.prevent="submitForm"
      >
        <AppInput
          v-model="form.email"
          :placeholder="$t('field_placeholder.email')"
          :validation-list="validationFields.email"
          show-validation-message
          :server-validation="serverValidationEmail"
          style="margin-top: 23px"
          :onBlur="findEmail"
        />

        <AppFormAlert
          v-if="error"
          type="error"
          @close="error = false"
        >
          {{ $t('reset_password.errors.email_not_found') }}
        </AppFormAlert>

        <AppFormAlert
          v-if="success"
          type="success"
          @close="success = false"
        >
          {{ $t('reset_password.success.send_form') }}
        </AppFormAlert>

        <AppLoader
          v-if="isLoading"
          style="margin: 25px auto 0 auto"
        />
        <AppButton
          v-if="!isLoading"
          :disabled="!isFormValid"
          class="password-reset__form__button"
        >
          {{ $t('reset_password.form.button.submit') }}
        </AppButton>
      </form>
    </template>
    <template #bottom>
      <div class="login-link-footer">
        <AppLink
          tag="p"
          :to="{name: userRouteList.USER_LOGIN_NAME}"
          class="login-link-footer__link-item"
        >
          {{ $t('Авторизация') }}
        </AppLink>
        <AppLink
          tag="p"
          :to="{name: userRouteList.USER_ORGANIZATION_REGISTRATION_ROUTE_NAME}"
          class="login-link-footer__link-item"
        >
          {{ $t('login.form.link.registration') }}
        </AppLink>
      </div>
    </template>
  </AppMainWindowContainer>
</template>

<script>
import AppMainWindowContainer from '@/components/common/layout/AppMainWindowContainer'
import AppInput from '@/components/common/simple/AppInput'
import AppButton from '@/components/common/simple/AppButton'
import { validateEmail } from '@/utils/validation'
import AppFormAlert from '@/components/common/AppFormAlert'
import AppLink from '@/components/common/simple/AppLink'
import { routeList } from '@/router/user'
import AppLoader from '@/components/AppLoader'

export default {
  name: 'PageResetPassword',
  components: { AppLoader, AppFormAlert, AppButton, AppInput, AppMainWindowContainer, AppLink },
  data () {
    return {
      isLoading: false,
      userRouteList: null,
      serverValidationEmail: null,
      emailFound: 0,
      form: {
        email: '',
      },
      error: false,
      success: false,
      validationFields: {
        email: [
          { text: this.$t('field_validation.email.not_valid_format'), valid: true, validator: validateEmail }
        ],
      },
    }
  },
  computed: {
    isFormValid () {
      return this.emailFound === 1 && this.validationFields.email.every((el) => el.valid)
    },
  },
  watch: {
    'form.email' (newValue, oldValue) {
      if (newValue !== oldValue && this.emailFound !== 1) {
        this.emailFound = 0
        this.serverValidationEmail = null
      }
    },
  },
  created () {
    this.userRouteList = routeList
  },
  methods: {
    findEmail () {
      const rawEmail = this.form.login
      if (rawEmail && rawEmail.length) {
        this.form.login = rawEmail.trim()
      }
      const { email } = this.form
      if (validateEmail(email)) {
        this.$store.dispatch('users/fetchCheckEmail', { email })
          .then((response) => {
            this.emailFound = response.data.count
            if (this.emailFound < 1) {
              this.serverValidationEmail = [{ 'propertyPath': 'email', 'title': 'Почта не найдена в системе' }]
            }
          })
          .catch((error) => {
            this.serverValidationEmail = error.data.violationss
          })
      }
    },
    submitForm () {
      if (this.isFormValid) {
        this.isLoading = true
        this.$store.dispatch('users/fetchResetPassword', this.form)
          .then(() => {
            this.error = false
            this.isLoading = false
            this.success = true
          })
          .catch(() => {
            this.isLoading = false
            this.error = true
            this.success = false
          })
      }
    },
  },

}
</script>

<style lang="sass" scoped>
  .login-link-footer
    display: flex
    flex-direction: column
    text-align: center
    align-items: center
    &__link-item
      width: 100%
      height: 1px
      margin: 13px 0

  .password-reset

    &__header
      text-align: center
      margin-bottom: 40px

    &__form
      display: flex
      flex-direction: column
      text-align: center
      align-items: center
      &__button
        width: 90%
        margin-top: 45px

    &__splice-line
      width: 100%
      height: 1px
      margin: 30px 0
      background-color: $line-color_gray


</style>
